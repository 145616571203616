body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 768px) {
  #prefix__Layer_1 {
    position: absolute;
    left: -30px;
  }
}
.back-to-top-button {
  border: 2px solid transparent;
  transition: all 0.2s ease;
}
.back-to-top-button:hover {
  border: 2px solid #304633;
  box-shadow: none;
  transition: all 0.2s ease;
}
#qamposection-svg {
  border-radius: 100%;
  border: 10px solid #ffffffba;
}
#qamposection-svg path{
  fill: #fff !important;
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
.clipping-button{
    padding: 1rem 2.5rem;
    border: none;
    color: white;
    background-color: #70a160;
    font-weight: 600;
    font-size: 16px;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    transition: background-color 1s ease;
    cursor: pointer;
}

  .clipping-button:hover {
    background-color: #58804b
  }
  
  table {
    width: 100%;
  }
  
  thead th {
    font-weight: 600;
    background: #70a160;
    color: #FFF;
  }
  
  tr {
    background: #f4f7f8;
    border-bottom: 1px solid #FFF;
    margin-bottom: 5px;
  }
  
  tr:nth-child(even) {
    background: #e8eeef;
  }
  
  th, td {
    text-align: left;
    padding: 20px;
    font-weight: 300;
  }
  
  tfoot tr {
    background: none;
  }
  
  tfoot td {
    padding: 10px 2px;
    font-size: 0.8em;
    font-style: italic;
    color: #8a97a0;
  }

  .slick-slider-image{
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (min-width: 0px) {
  
  }
  @media only screen and (min-width: 768px) {
    .slick-slider-image{
      height: 200px;
    }
  }
  @media only screen and (min-width: 1024px) {
    .slick-slider-image{
      height: 200px;
    }
  }
  @media only screen and (min-width: 1440px) {
    .slick-slider-image{
      height: 250px;
    }
  }

.slick-list {margin: 0 -10px;}
.slick-slide>div {padding: 0 1rem;}

.video-play-button {
  position: relative;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
}
.green-section {
  background-color: rgb(231, 241, 223);
}

.red-section {
  background-color: rgb(241, 223, 223);
}

.yellow-section {
  background-color: rgb(241, 235, 223);
}

.blue-section {
  background-color: #e6f8f8
}
.video-play-button svg {
  position: absolute;
  z-index: 3;
  left: calc(43%);
}

.video-play-button:after {
  content: "";
  position: absolute;
  width: 80%;
  z-index: 1;
  height: 80%;
  background: rgb(255, 255, 255);
  opacity: 0.6;
  border-radius: 50%;
}

.salicornia-btn-hero{
  transition: 0.5s ease;
}
.salicornia-btn-hero:hover{
  background-color: #58804b !important;
}