
.inputs{
    padding: 12px 20px;
    margin: 8px 0;
    font-family: inherit;
}

.inputs.text_input{
  padding: 25px 20px;
}


.button {
    text-align: center;
    position: relative;
    display: block;
    width: 200px;
    height: 36px;
    border-radius: 18px;
    background-color: #7C9A64;
    border: solid 1px transparent;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
    transition: all .1s ease-in-out;
   
    
  }

  .button:hover {
    background-color: #60774d;
    border-color: #fff;
    transition: all .1s ease-in-out;
  }