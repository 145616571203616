.modal_box{
    /*cursor: pointer;*/
}

.loader{
    border: 4px solid #CCE6F4;
    border-top-color: #71a060;
    border-right-color: #b94853;
    border-bottom-color: #e3af62;
    border-left-color: #5baeb4;
    animation: rotate 2s infinite;
    border-radius: 50%;
}

@keyframes rotate{
0%{
    transform: rotate(0deg);
}
10%{
    transform: rotate(-45deg);
}
100%{
    transform: rotate(720deg);
}
}